<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="店铺名称：">
        <el-input size="small" v-model="searchForm.merchant_name"></el-input>
      </el-form-item>
      <el-form-item label="商家姓名：">
        <el-input size="small" v-model="searchForm.contact_name"></el-input>
      </el-form-item>
      <el-form-item label="商家电话：">
        <el-input size="small" v-model="searchForm.store_phone"></el-input>
      </el-form-item>
      <el-form-item label="二维码ID：">
        <el-input size="small" v-model="searchForm.sn"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="已通过" :value="2"></el-option>
          <el-option label="未通过" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="sn" label="二维码" width="200" align="center"></el-table-column>
      <el-table-column prop="merchant_name" label="店铺名称" width="200" align="center"></el-table-column>
      <el-table-column prop="contact_name" label="商家姓名" align="center"></el-table-column>
      <el-table-column prop="store_phone" label="商家电话" align="center"></el-table-column>
      <el-table-column prop="store_site" label="店铺地址" align="center"></el-table-column>
      <el-table-column prop="store_logo" label="店铺logo" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 50px; height: 50px; border-radius: 50%" :src="row.store_logo"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="business_license" label="经营资质" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 100px; height: 50px" fit="cover" :src="row.business_license" :preview-src-list="[row.business_license]"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">
          <span>
            {{ row.status == 1 ? '待审核' : row.status == 2 ? '已通过' : '未通过' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="申请时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <div class="btn" v-if="row.status == 1">
            <el-button @click="review(row, 2)" type="text" size="small">通过</el-button>
            <el-button @click="review(row, 3)" type="text" size="small">驳回</el-button>
          </div>
          <el-button v-if="row.status == 3" @click="(remark = row.remark), (showRemark = !0)" type="text" size="small">驳回原因</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="审核" :visible.sync="showReview" width="600px">
      <el-form label-width="140px">
        <el-form-item label="请选择审核结果：">
          <el-radio-group v-model="status">
            <el-radio :label="2">通过</el-radio>
            <el-radio :label="3">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="请添加驳回原因：" v-if="status == 3">
          <el-input type="textarea" :rows="4" v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showReview = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmReview">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="驳回原因" :visible.sync="showRemark" width="600px">
      {{ remark }}
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showRemark = !1">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        merchant_name: '',
        contact_name: '',
        store_phone: '',
        sn: '',
        status: 0,
        start_time: '',
        end_time: '',
      },
      showReview: !1,
      showRemark: !1,
      id: '',
      status: 2,
      remark: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        merchant_name: '',
        contact_name: '',
        store_phone: '',
        sn: '',
        status: 0,
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.set.storeSettled, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    review(row, status) {
      this.status = status;
      this.id = row.id;
      this.remark = '';
      this.showReview = !0;
    },
    comfirmReview() {
      if (!this.remark && this.status == 3) return this.$message.warning('请添加驳回原因');
      this.$axios
        .post(this.$api.set.storeSettledUpStatus, {
          id: this.id,
          status: this.status,
          remark: this.remark,
        })
        .then(res => {
          if (res.code == 0) {
            this.showReview = !1;
            this.$message.success('处理成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>